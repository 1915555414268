import * as React from "react";
import {Filter, List, TextField, TextInput,BooleanField} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {CustomActionsButtons} from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
    return (
        <List
            {...props}
            title={`Lista badań diagnostycznych`}
            perPage={25}
            pagination={<CustomPagination />}
            bulkActionButtons={false}
            actions={<CustomActionBar/>}
            empty={<EmptyList title="badań diagnostycznych" add={true}/>}
            filters={
                <Filter>
                    <TextInput
                        label="Nazwa"
                        source="name"
                        variant="outlined"
                        alwaysOn
                        resettable
                    />
                </Filter>
            }
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa"/>
                <BooleanField
                    source="public"
                    label="Opublikowany"
                />

                <CustomActionsButtons label="Akcje" notUndoable={true}/>
            </CustomDataGrid>
        </List>
    );
};
