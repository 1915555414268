import decodeJwt from "jwt-decode";

export default {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/../authentication_token`,
      {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken = decodeJwt(token);

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", decodedToken.roles);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  register: ({ payerData, users }) => {
    //console.log(payerData, users);
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (isPublicUrl(window.location.hash)) {
      return Promise.resolve();
    }
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  hasRole: (roles) => {
    const userroles = localStorage.getItem("permissions");
    return roles.some((role) => userroles.split(",").includes(role));
  },
  getPermissions: () => {

    if (isPublicUrl(window.location.hash)) {
      return Promise.resolve();
    }

    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

function isPublicUrl(hash) {
  const publicUrls = [
    "#/singin",
    //'#/login',
  ];
  return publicUrls.includes(hash) ? true : false;
}
