import * as React from "react";
import { ListButton, Toolbar, SaveButton, DeleteButton } from "react-admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUndo} from "@fortawesome/free-solid-svg-icons";

export const PostBottomToolbarEdit = (props) => {
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton
        //class={"btn btn-success btn-lg"}
        className="btn-ok"
        redirect={
          props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        }
        submitOnEnter={true}
        disabled={props.disabled}
      />
      <ListButton
        label="Anuluj"
        //class={"btn btn-secondary btn-lg"}
        className="btn-cancel"
        basePath={props.basePath}
        icon={<FontAwesomeIcon icon={faUndo} />}
        disabled={props.disabled}
      />
      {(props.isDeleteAvailable == undefined ||
        props.isDeleteAvailable == false) && (
        <DeleteButton
          //class={"btn btn-danger btn-lg"}
          className="btn-delete"
          redirect={props.basePath}
          disabled={props.disabled}
        />
      )}
    </Toolbar>
  );
};

export const PostBottomToolbarAdd = (props) => {
  return (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton
        //class={"btn btn-success btn-lg"}
        className="btn-ok"
        redirect={
          props.saveRedirectPath ? props.saveRedirectPath : props.basePath
        }
        submitOnEnter={true}
      />
      <ListButton
        label="Anuluj"
        //class={"btn btn-secondary btn-lg"}
        className="btn-cancel"
        basePath={props.basePath}
        icon={<FontAwesomeIcon icon={faUndo} />}
      />
    </Toolbar>
  );
};
