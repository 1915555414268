import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";
import { Field } from "react-final-form";


import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";
import AddFilePopup from "../CustomElements/AddFilePopup";

const requiredField = required();

const CustomTitle = (props) => `Aktualność ${props.record.name}`;

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth={true}
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="description">
                      {(props) => <TinyMCEInput source="description" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup source="image" reference="media_objects" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                      source="showOnMainSite"
                      label="Wyświetlaj na stronie głównej"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <AddFilePopup source="files" reference="files" />
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
