import React from "react";
import {useSelector} from "react-redux";
import {useMediaQuery} from "@material-ui/core";
import {MenuItemLink, useDataProvider, usePermissions} from "react-admin";
import {withRouter} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAlignCenter,
    faBlog,
    faClinicMedical, faClone,
    faCopy,
    faDiagnoses,
    faFileUpload,
    faGripHorizontal,
    faHospitalUser,
    faImages,
    faNewspaper,
    faQuestion,
    faUserNurse,
    faUsers,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const Menu = ({onMenuClick, logout}) => {
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const {loaded, permissions} = usePermissions();

    return loaded ? (
        <div className="left-menu">
            {/* <MenuItemLink
        to="/#"
        primaryText="Strona główna"
        leftIcon={<FontAwesomeIcon icon={faHome} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}

            <MenuItemLink
                to="/staff"
                primaryText="Lekarze"
                leftIcon={<FontAwesomeIcon icon={faUserNurse}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/clinics"
                primaryText="Poradnie"
                leftIcon={<FontAwesomeIcon icon={faClinicMedical}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/hospital_sections"
                primaryText="Oddziały szpitala"
                leftIcon={<FontAwesomeIcon icon={faHospitalUser}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/diagnoses"
                primaryText="Badania diagnostyczne"
                leftIcon={<FontAwesomeIcon icon={faDiagnoses}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {/*<MenuItemLink*/}
            {/*    to="/type_of_diagnoses"*/}
            {/*    primaryText="Typy diagnostyki"*/}
            {/*    leftIcon={<FontAwesomeIcon icon={faStethoscope}/>}*/}
            {/*    onClick={onMenuClick}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*/>*/}
            <MenuItemLink
                to="/prices"
                primaryText="Cennik"
                leftIcon={<FontAwesomeIcon icon={faFileUpload}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <hr/>
            {/*<MenuItemLink*/}
            {/*    to="/categories"*/}
            {/*    primaryText="Kategorie"*/}
            {/*    leftIcon={<FontAwesomeIcon icon={faListAlt}/>}*/}
            {/*    onClick={onMenuClick}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<MenuItemLink*/}
            {/*  to="/banners"*/}
            {/*  primaryText="Banery"*/}
            {/*  leftIcon={<FontAwesomeIcon icon={faAd} />}*/}
            {/*  onClick={onMenuClick}*/}
            {/*  sidebarIsOpen={open}*/}
            {/*/>*/}
            <MenuItemLink
                to="/contents"
                primaryText="Lista dokumentów"
                leftIcon={<FontAwesomeIcon icon={faAlignCenter}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/news"
                primaryText="Aktualności"
                leftIcon={<FontAwesomeIcon icon={faNewspaper}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/banners"
                primaryText="Bannery"
                leftIcon={<FontAwesomeIcon icon={faClone}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/blogs"
                primaryText="Blogi"
                leftIcon={<FontAwesomeIcon icon={faBlog}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/galleries"
                primaryText="Galerie"
                leftIcon={<FontAwesomeIcon icon={faImages}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {/*<MenuItemLink*/}
            {/*    to="/blog_tags"*/}
            {/*    primaryText="Tagi"*/}
            {/*    leftIcon={<FontAwesomeIcon icon={faTags}/>}*/}
            {/*    onClick={onMenuClick}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*/>*/}

            <MenuItemLink
                to="/information_lists"
                primaryText="Boxy tekstowe"
                leftIcon={<FontAwesomeIcon icon={faInfoCircle}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/faqs"
                primaryText="FAQ"
                leftIcon={<FontAwesomeIcon icon={faQuestion}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/faq_types"
                primaryText="Kategorie FAQ"
                leftIcon={<FontAwesomeIcon icon={faGripHorizontal}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <MenuItemLink
                to="/media_objects"
                primaryText="Repozytorium zdjęć"
                leftIcon={<FontAwesomeIcon icon={faImages}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/files"
                primaryText="Repozytorium plików"
                leftIcon={<FontAwesomeIcon icon={faFileUpload}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/file_types"
                primaryText="Kategorie plików"
                leftIcon={<FontAwesomeIcon icon={faCopy}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <hr/>
            <MenuItemLink
                to="/users"
                primaryText="Użytkownicy"
                leftIcon={<FontAwesomeIcon icon={faUsers}/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    ) : null;
};

export default withRouter(Menu);
