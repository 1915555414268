import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

const CustomTitle = (props) => `Typ pliku ${props.record.name}`;

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      fullWidth
                      variant="outlined"
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      reference="files"
                      source="files"
                      label="Pliki"
                      fullWidth
                    >
                      <SelectArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
