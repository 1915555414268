import React, { useState, useEffect } from "react";
import {
  useRedirect,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { useDispatch } from "react-redux";
import keyBy from "lodash/keyBy";

import authProvider from "../../config/authProvider";

import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TextField as MuiTextField } from "@material-ui/core";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";

import CircularProgress from "@material-ui/core/CircularProgress";

import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

import EmptyList from "../EmptyList";

const ROOT_NODE_ID = "/categories/root"; //SPECIFY ROOT NODE @id

const DataGridConverter = (dataObj) => {
  const flatArr = [];
  const getAllItemsPerChildren = (item) => {
    flatArr.push(item);
    if (item.children) {
      return item.children.map((i) => getAllItemsPerChildren(i));
    }
  };

  getAllItemsPerChildren(dataObj);
  flatArr.map((o) => Object.assign(o, { id: o["@id"] })["@id"]);
  //console.log(flatArr);

  return flatArr.filter((x) => x["@id"] !== "/api" + ROOT_NODE_ID); //hide root category;
};

const TreeGrid = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  //const refresher = useRefresh();
  const [changed, setChanged] = useState(false);

  if (props?.data) props.data.expanded = true;
  const data = props.data ? [props.data] : [];

  const [treeData, setTreeData] = useState(data);
  useEffect(() => {
    setTreeData(data);
  }, [JSON.stringify(data)]);

  const changedStructure = (node, nextParentNode) => {
    let changedData = {};
    if (node && nextParentNode) {
      let category = { id: node["@id"], slug: node.slug };
      let newParent = {
        id: nextParentNode["@id"],
        slug: nextParentNode.slug,
      };
      changedData = { category, newParent };
    } else if (node && !nextParentNode) {
      let category = { id: node["@id"], slug: node.slug };
      let newParent = {
        id: null,
        slug: null,
      };
      changedData = { category, newParent };
    }
    sendChangedData(changedData);
  };

  const sendChangedData = (data) => {
    let childId = data.category.slug;
    let newParentId = data.newParent.id;
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/categories/${childId}`, {
      method: "PATCH",
      body: JSON.stringify({ parent: `${newParentId}` }),
      headers: {
        "Content-Type": "application/merge-patch+json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.status != 200) throw response;
        props.setRefresh((old) => !old);
      })
      .catch((e) => {
        notify("Błąd zmiany pozycji", "warning");
        props.setRefresh((old) => !old);
      });
  };

  const renderButton = (node) => {
    return (
      <button
        class="btn btn-success"
        onClick={() =>
          redirect(`/categories/${encodeURIComponent(node["@id"])}`)
        }
      >
        Edytuj
      </button>
    );
  };

  return (
    <div style={{ height: 600, margin: 25 }}>
      {!props.loading && treeData.length > 0 ? (
        <SortableTree
          treeData={treeData}
          onChange={(treeData) => {
            setTreeData(treeData);
          }}
          getNodeKey={({ node }) => node["@id"]}
          onMoveNode={({ node, nextParentNode }) => {
            changedStructure(node, nextParentNode);
            setChanged(true);
          }}
          generateNodeProps={({ node, path }) => ({
            title: node.name,
            buttons: node.parent != null ? [renderButton(node)] : [],
            canDrag: node.parent != null,
          })}
          canDrop={({ node, nextParent }) => nextParent != null}
        />
      ) : (
        <div style={{ margin: 50 }}>
          <CircularProgress size={100} />
        </div>
      )}
    </div>
  );
};

export default (props) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [catData, setCatData] = useState(null);
  const [catDataFlat, setCatDataFlat] = useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchStart());
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${ROOT_NODE_ID}`)
      .then(async (data) => {
        if (data.status != 200) throw data;
        return await data.json();
      })
      .then((json) => {
        setLoading(false);
        setCatData(json);
        setCatDataFlat(DataGridConverter(json));
        dispatch(fetchEnd());
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        dispatch(fetchEnd());
      });
  }, [refresh]);

  //console.log(refresh);

  //TODO: fix list view, fix refreshing

  return (
    <>
      <CustomActionBar
        tabs={
          <>
            {value == 1 && (
              <MuiTextField
                label="Nazwa"
                variant="outlined"
                onChange={(event) => setNameFilter(event.target.value)}
                disabled={!catDataFlat}
              />
            )}
            <Tabs value={value} onChange={handleChange} {...props}>
              <Tab label="Drzewo" />
              <Tab label="Lista" />
            </Tabs>
          </>
        }
      />
      <Card style={{ margin: "15px 0 15px 0" }}>
        {value == 0 ? (
          <TreeGrid
            data={catData}
            loading={loading}
            error={error}
            setRefresh={setRefresh}
          />
        ) : catDataFlat ? (
          <CustomDataGrid
            data={keyBy(
              !!nameFilter
                ? catDataFlat.filter((el) => el?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase()))
                : catDataFlat,
              "@id"
            )}
            ids={(!!nameFilter
              ? catDataFlat.filter((el) => el?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase()))
              : catDataFlat
            )?.map((el) => el["@id"])}
            currentSort={{ field: "@id", order: "ASC" }}
          >
            <TextField source="slug" label="Slug" />
            <TextField source="name" label="Nazwa" />
            <CustomActionsButtons
              label="Akcje"
              customBasePath={"/categories"}
              notUndoable={true}
              onSuccess={() => setRefresh((old) => !old)}
            />
          </CustomDataGrid>
        ) : (
          <div style={{ margin: 50 }}>
            <CircularProgress size={100} />
          </div>
        )}
      </Card>
    </>
  );
};
