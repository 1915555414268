import * as React from "react";
import {Filter, List, ReferenceField, TextField, TextInput,} from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {CustomActionsButtons} from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Do pobrania`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="plików do pobrania" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <TextField source="filename" label="Plik" />
        <ReferenceField source="type" reference="file_types" label="Typ">
          <TextField source="name" />
        </ReferenceField>
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
