import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Login as BaseLogin } from "react-admin";
import { useTranslate, useLogin, useNotify, useSafeSetState } from "ra-core";

import { useSnackbar } from "notistack";

import logo from "../../logo.png";

const useStyles = makeStyles((theme) => ({
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
    width: "450px",
    margin: "auto",
  },
  input: {
    marginTop: "1em",
    width: "300px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  loginLabel: {
    fontWeight: "bold",
    marginRight: "20px",
    color: "#3C424A",
  },
}));

const styles = {
  login: {
    main: {
      background: "none",
      height: "auto",
      minHeight: "inherit",
    },
    card: {
      boxShadow: "none",
      marginTop: "0px",
      minWidth: "0px",
    },
    avatar: {
      display: "none",
    },
  },
};

const Login = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  //const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        localStorage.setItem("notify", true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message == 401)
          enqueueSnackbar("Niepoprawna nazwa użytkownika lub hasło", {
            variant: "error",
            autoHideDuration: 10000,
          });
        else {
          enqueueSnackbar("Błąd połączenia", {
            variant: "error",
            autoHideDuration: 10000,
          });
        }
      });
  };

  return (
      <div className="thin-wrapper">
        <div className="inner login-panel">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="title">
            <p>Panel admina</p>
          </div>
          <BaseLogin {...props}>
            <Form
              onSubmit={submit}
              validate={validate}
              initialValues={{
                username: "",
                password: "",
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  {/* <div className={classes.hint}>
              Wskazówka: admin@example.com / admin
            </div> */}
                  <div className="form-login">
                    <div className="form-group">
                      <label className={classes.loginLabel}>
                        {translate("ra.auth.username")}
                      </label>

                      <Field
                        autoFocus
                        id="username"
                        name="username"
                        placeholder="e-mail"
                        //component={Input}
                        component="input"
                        className="form-control"
                        //label={translate("ra.auth.username")}
                        disabled={loading}
                      />
                    </div>
                    <div className="form-group">
                      <label className={classes.loginLabel}>
                        {translate("ra.auth.password")}
                      </label>

                      <Field
                        id="password"
                        name="password"
                        //component={Input}
                        placeholder="hasło"
                        component="input"
                        className="form-control"
                        //label={translate("ra.auth.password")}
                        type="password"
                        disabled={loading}
                        autoComplete="current-password"
                      />
                    </div>

                    <div className="d-flex flex-wrap">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disableElevation
                        disabled={loading}
                        className="primary full-width"
                      >
                        {loading && (
                          <CircularProgress
                            className={classes.icon}
                            size={18}
                            thickness={2}
                          />
                        )}
                        {translate("ra.auth.sign_in")}
                      </Button>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <p className="lost-paswd">
                        Jeśli nie pamiętasz hasła, skorzytaj z funkcji
                        resetowania hasła dostępnej{" "}
                        <a href="#/reset_password">tutaj</a>
                      </p>
                    </div>
                  </div>
                </form>
              )}
            />
          </BaseLogin>
        </div>
      </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default withStyles(styles.login)(Login);
