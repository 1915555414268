import React, { useState } from "react";
import {
  Edit,
  ImageInput,
  ImageField,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { useDispatch } from "react-redux";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

const CustomTitle = (props) => `Zdjęcie ${props.record.id.split("/")[3]}`;

export default (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm
        toolbar={<PostBottomToolbarEdit disabled={loading} />}
        save={(data) => {
          if (!data.file) return;
          const img = new FormData();
          img.append("file", data.file.rawFile, data.file.rawFile.name);

          dispatch(fetchStart());
          setLoading(true);

          fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}/media_objects/${
              data.id.split("/")[3]
            }`,
            {
              method: "POST",
              body: img,
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
            .then((resp) => {
              if (resp.status != 201) throw resp;
              resp.json().then((respData) => {
                dispatch(fetchEnd());
                setLoading(false);
                redirect("/media_objects");
                notify("Zdjęcie zostało podmienione");
              });
            })
            .catch((error) => {
              notify(`Wystąpił błąd: ${error.status}`, "warning");
              dispatch(fetchEnd());
              setLoading(false);
            });
        }}
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <ImageInput
                      source="file"
                      label="Zdjęcie"
                      accept="image/*"
                      validate={requiredField}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
