import React from "react";
import {
    AutocompleteArrayInput,
    Edit,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput,
    BooleanInput
} from "react-admin";
import {Field} from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";
import AddFilePopup from "../CustomElements/AddFilePopup";
import OrderedArrayInput from "../CustomElements/OrderedArrayInput";

const requiredField = required();

const CustomTitle = (props) => `Badanie diagnostyczne ${props.record.name}`;

export default (props) => {
    return (
        <Edit {...props} title={<CustomTitle/>}>
            <SimpleForm toolbar={<PostBottomToolbarEdit/>}>
                <div className="d-flex w-100 justify-content-around">
                    <div class="col-xs-6">
                        <div class="table">
                            <table class="table table-striped">
                                <tr>
                                    <td>
                                        <TextInput
                                            source="name"
                                            label="Nazwa"
                                            variant="outlined"
                                            fullWidth
                                            validate={requiredField}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <AddImagePopup source="icon" reference="media_objects"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label
                                            style={{
                                                fontFamily: "arial",
                                                color: "rgba(0, 0, 0, 0.54)",
                                                display: "block",
                                            }}
                                        >
                                            Treść krótka
                                        </label>
                                        <Field name="short">
                                            {(props) => (
                                                <TinyMCEInput
                                                    source="short"
                                                    height={200}
                                                    menubar={false}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label
                                            style={{
                                                fontFamily: "arial",
                                                color: "rgba(0, 0, 0, 0.54)",
                                                display: "block",
                                            }}
                                        >
                                            Opis pełny
                                        </label>
                                        <Field name="preparation">
                                            {(props) => (
                                                <TinyMCEInput
                                                    source="preparation"
                                                    height={200}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*  <td>*/}
                                {/*    <ReferenceInput*/}
                                {/*      reference="type_of_diagnoses"*/}
                                {/*      source="type"*/}
                                {/*      label="Typ"*/}
                                {/*      fullWidth*/}
                                {/*      validate={requiredField}*/}
                                {/*    >*/}
                                {/*      <SelectInput variant="outlined" fullWidth />*/}
                                {/*    </ReferenceInput>*/}
                                {/*  </td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>
                                        <TextInput
                                            source="formUrl"
                                            label="Link do formularza"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ReferenceArrayInput
                                            source="clinics"
                                            reference="clinics"
                                            label="Poradnie"
                                            perPage={1000}
                                            fullWidth
                                        >
                                            <AutocompleteArrayInput variant="outlined" fullWidth/>
                                        </ReferenceArrayInput>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ReferenceArrayInput
                                            source="hospitalSections"
                                            reference="hospital_sections"
                                            label="Oddziały szpitala"
                                            perPage={1000}
                                            fullWidth
                                        >
                                            <AutocompleteArrayInput variant="outlined" fullWidth/>
                                        </ReferenceArrayInput>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ReferenceArrayInput
                                            source="staff"
                                            reference="staff"
                                            label="Lekarze"
                                            perPage={1000}
                                            fullWidth
                                        >
                                            <AutocompleteArrayInput variant="outlined" fullWidth/>
                                        </ReferenceArrayInput>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ReferenceArrayInput
                                            source="blogs"
                                            reference="blogs"
                                            label="Artykuły blogowe"
                                            perPage={1000}
                                            fullWidth
                                        >
                                            <AutocompleteArrayInput variant="outlined" fullWidth/>
                                        </ReferenceArrayInput>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <ReferenceArrayInput
                                            source="prices"
                                            reference="prices"
                                            label="Cennik"
                                            perPage={1000}
                                            fullWidth
                                        >
                                            <OrderedArrayInput>
                        <AutocompleteArrayInput variant="outlined" fullWidth optionText='name' />
                                            </OrderedArrayInput>
                                        </ReferenceArrayInput>
                                    </td>
                                    {/*<td>*/}
                                    {/*  <ReferenceArrayInput*/}
                                    {/*      source="prices"*/}
                                    {/*      reference="prices"*/}
                                    {/*      label="Cennik"*/}
                                    {/*      perPage={1000}*/}
                                    {/*      fullWidth*/}
                                    {/*  >*/}
                                    {/*    <OrderedArrayInput>*/}
                                    {/*      <AutocompleteArrayInput variant="outlined" fullWidth optionText='name' />*/}
                                    {/*    </OrderedArrayInput>*/}
                                    {/*  </ReferenceArrayInput>*/}
                                    {/*</td>*/}
                                </tr>
                                <tr>
                                    <td>
                                        <AddFilePopup
                                            source="files"
                                            reference="files"
                                            label="Do pobrania"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <BooleanInput
                                            source="public"
                                            label="Publiczne"
                                            variant="outlined"
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    );
};
