import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  NumberInput,
  ReferenceArrayInput,
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import {Field} from "react-final-form";
import TinyMCEInput from "../TinyMCEInput";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={"Dodaj cennik"}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-10">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                        source="code"
                        label="KOD"
                        fullWidth
                        variant="outlined"
                        validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                        source="optimedName"
                        label="Nazwa w optimed"
                        fullWidth
                        variant="outlined"
                        validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                        source="name"
                        label="Nazwa dla pacjenta"
                        fullWidth
                        variant="outlined"
                        validate={requiredField}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <TextInput
                        source="price"
                        label="Cena dla pacjenta"
                        fullWidth
                        variant="outlined"
                        validate={requiredField}
                    />
                  </td>
                </tr>


                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="clinic"
                        reference="clinics"
                        label="Poradni"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="hospitalSection"
                        reference="hospital_sections"
                        label="Oddziały szpitala"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="diagnosis"
                        reference="diagnoses"
                        label="Diagnostyki"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>

              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
