import * as React from "react";
import { Filter, List, TextField, TextInput } from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Typy plików`}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="typów plików" add={true} />}
      perPage={25}
      pagination={<CustomPagination />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
