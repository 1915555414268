import React, {useEffect, useState} from "react";

import {
    CardActions,
    CreateContextProvider,
    ImageField,
    ImageInput,
    SaveButton,
    SimpleForm,
    TextField,
    Toolbar,
    useCreateController,
    useDataProvider,
    useQuery,
} from "react-admin";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Button, createStyles, makeStyles, MenuItem, Select,} from "@material-ui/core";
import keyBy from "lodash/keyBy";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Field, useForm} from "react-final-form";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

const CreateImagePopup = (props) => {
    const useStylesCards = makeStyles(() =>
        createStyles({
            root: {
                position: "fixed",
                top: "50%",
                left: "60%",
                zIndex: 1302,
                transform: "translate(-50%, -50%)",
                width: "750px",
                overflow: "visible",
            },
        })
    );
    const cardClasses = useStylesCards();

    const [name, setName] = useState(null);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [sort, setSort] = useState({field: "id", order: "ASC"});
    const {data, total, loading, error} = useQuery({
        type: "getList",
        resource: props.reference,
        payload: {
            pagination: {page, perPage},
            sort,
            filter: name ? {filePath: name} : {},
        },
    });

    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const form = useForm();

    const ImagePagination = (props) => {
        const nbPages = Math.ceil(total / perPage) || 1;
        return (
            nbPages > 1 && (
                <Toolbar style={{justifyContent: "space-between"}}>
                    {page > 1 && (
                        <Button
                            color="primary"
                            key="prev"
                            onClick={() => setPage(page - 1)}
                        >
                            <ChevronLeft/>
                            Wstecz
                        </Button>
                    )}
                    {page !== nbPages && (
                        <Button
                            color="primary"
                            key="next"
                            onClick={() => setPage(page + 1)}
                        >
                            Dalej
                            <ChevronRight/>
                        </Button>
                    )}
                    <div>
                        <span>Elementów na stronę:</span>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => {
                                setPerPage(e.target.value);
                            }}
                            value={perPage}
                            style={{margin: "0 10px 0 10px"}}
                        >
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                        </Select>
                    </div>
                </Toolbar>
            )
        );
    };

    return (
        <>
            <div className="img-popup-overlay" onClick={props.handleVisability}/>
            <Card classes={cardClasses}>
                <div className="img-popup-close" onClick={props.handleVisability}>
                    X
                </div>
                <CardContent>
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography style={{fontSize: "1.5rem", fontWeight: "bold"}}>
                                Wybierz z repozytorium
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{flex: 1}}>
                                <label className="popup-label">Nazwa pliku</label>
                                <input
                                    label="Nazwa pliku"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setPage(1);
                                    }}
                                    value={name}
                                    className="form-control"
                                    style={{width: "50%"}}
                                />
                                {loading && (
                                    <CircularProgress
                                        size={75}
                                        style={{margin: "115px 0 115px 75px"}}
                                    />
                                )}
                                {error && <p>Błąd: {error}</p>}
                                {!loading && !error && (
                                    <ImagesGrid
                                        data={keyBy(data, "id")}
                                        ids={data.map(({id}) => id)}
                                        currentSort={sort}
                                        setSort={(field, order) => setSort({field, order})}
                                        setImage={props.setImage}
                                        setImageLink={props.setImageLink}
                                        handleVisability={props.handleVisability}
                                        source={props.source}
                                    />
                                )}
                                {!error && <ImagePagination/>}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography style={{fontSize: "1.5rem", fontWeight: "bold"}}>
                                Dodaj nowe
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{flex: 1}}>
                                <div>
                                    <ImageCreate
                                        {...{
                                            basePath: `/${props.reference}`, //"/media_objects",
                                            resource: props.reference, //"media_objects",
                                        }}
                                        onSuccess={(e) => {
                                            props.setImage(e.data["@id"]);
                                            props.setImageLink(e.data.contentUrl);
                                            form.change(props.source, e.data["@id"]);
                                            props.handleVisability();
                                        }}
                                    >
                                        <SimpleForm toolbar={<PostBottomToolbarImage/>}>
                                            <ImageInput
                                                source="file"
                                                label="Dodane zdjęcie"
                                                accept="image/*"
                                            >
                                                <ImageField source="src" title="title"/>
                                            </ImageInput>
                                        </SimpleForm>
                                    </ImageCreate>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </>
    );
};

const ImageCreate = (props) => {
    const createControllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;
    return (
        <CreateContextProvider value={createControllerProps}>
            <div>
                {React.cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        </CreateContextProvider>
    );
};

const PostBottomToolbarImage = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton
                className="btn-ok"
                style={{fontSize: "0.5em", width: "200px"}}
                redirect={false}
                submitOnEnter={true}
                label="Zapisz i dodaj"
            />
        </Toolbar>
    );
};

const ImagesGrid = (props) => {
    const {ids, data, basePath} = props;

    const cardStyle = {
        width: 150,
        margin: "0.5em",
        display: "inline-block",
        verticalAlign: "top",
        wordWrap: "break-word",
        cursor: "pointer",
    };

    const useStyles = makeStyles({
        root: {
            "&:hover": {backgroundColor: "#c3e6cb"},
        },
    });
    const classesCard = useStyles();
    const form = useForm();
    return (
        <div className="popup-cards-wrapper">
            {ids.map((id) => (
                <Card
                    key={id}
                    style={cardStyle}
                    onClick={() => {
                        props.setImage(data[id]["@id"]);
                        props.setImageLink(data[id].contentUrl);
                        form.change(props.source, data[id]["@id"]);
                        props.handleVisability();
                    }}
                    classes={classesCard}
                >
                    <CardContent>
                        <img src={data[id].contentUrl} style={{maxWidth: "100px"}}/>
                        <TextField record={data[id]} source="filePath"/>
                    </CardContent>
                    <CardActions style={{justifyContent: "center"}}></CardActions>
                </Card>
            ))}
        </div>
    );
};

export default (props) => {
    const dataProvider = useDataProvider();
    const form = useForm();
    const [popupVisability, setPopupVisability] = useState(false);
    const [imageForm, setImageForm] = useState(null);
    const [imageLink, setImageLink] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    const togglePopup = () => {
        setPopupVisability(!popupVisability);
    };

    useEffect(() => {
        if (imageForm) {
            setImageLoading(true);
            dataProvider
                .getOne("media_objects", {id: imageForm})
                .then(({data}) => {
                    setImageLoading(false);
                    setImageLink(data.contentUrl);
                })
                .catch((error) => {
                    setImageLoading(false);
                });
        }
    }, [imageForm]);

    return (
        <div>
            {popupVisability && (
                <CreateImagePopup
                    handleVisability={togglePopup}
                    setImage={setImageForm}
                    setImageLink={setImageLink}
                    reference={props.reference}
                    source={props.source}
                />
            )}
            {imageForm && imageLink ? (
                <div style={{display: "flex"}}>
                    <div style={{position: "relative"}}>
                        <div
                            className="img-popup-del"
                            onClick={() => {
                                setImageForm(null);
                                setImageLink(null);
                                form.change(props.source, null);
                            }}
                        >
                            X
                        </div>
                        <img src={imageLink} className="popup-image-prev"/>
                    </div>
                    <Field name={props.source} type="text">
                        {(props) => {
                            return <input {...props.input} style={{display: "none"}}/>;
                        }}
                    </Field>
                    <Field name={props.source} type="text">
                        {(props) => {
                            setImageForm(props.input.value);
                            return (
                                <>
                                    {imageLoading && (
                                        <CircularProgress
                                            size={50}
                                            style={{margin: "10px", display: "block"}}
                                        />
                                    )}
                                    <input {...props.input} style={{display: "none"}}/>
                                </>
                            );
                        }}
                    </Field>
                </div>
            ) : (
                <Field name={props.source} type="text">
                    {(props) => {
                        setImageForm(props.input.value);
                        return (
                            <>
                                {imageLoading && (
                                    <CircularProgress
                                        size={50}
                                        style={{margin: "10px", display: "block"}}
                                    />
                                )}
                                <input {...props.input} style={{display: "none"}}/>
                            </>
                        );
                    }}
                </Field>
            )}

            {imageForm && imageLink ? (
                <div></div>
            ) : (
                <button type="button" class="btn btn-success" onClick={togglePopup}>
                    Dodaj zdjęcie
                </button>
            )}
        </div>
    );
};
