import * as React from "react";
import {
  BooleanField,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();

  // const documentType = (type) => {
  //     switch (type) {
  //         case 0:
  //             return "Test1";
  //         case 1:
  //             return "Test2";
  //         default:
  //             return "-";
  //     }
  // };

  return (
    <List
      {...props}
      title={`Lista dokumentów`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="dokumentów" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        {/*<ReferenceArrayField*/}
        {/*    source="category"*/}
        {/*    reference="categories"*/}
        {/*    label="Kategoria"*/}
        {/*>*/}
        {/*    <SingleFieldList>*/}
        {/*        <ChipField source="name"/>*/}
        {/*    </SingleFieldList>*/}
        {/*</ReferenceArrayField>*/}
        {/*<FunctionField*/}
        {/*    source="type"*/}
        {/*    render={(record) => documentType(record.type)}*/}
        {/*    label="Typ dokumentu"*/}
        {/*/>*/}
        <BooleanField source="public" label="Status" className={classes.root} />
        <DateField source="createdAt" label="Utworzono" />
        <DateField source="updatedAt" label="Modyfikowano" />
        {/*<ReferenceField source="author" reference="users" label="Autor">*/}
        {/*    <TextField source="email"/>*/}
        {/*</ReferenceField>*/}
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
