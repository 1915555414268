import * as React from "react";
import { Filter, List, TextField, TextInput, Pagination } from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Cennik`}
      perPage={100}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="Cennik" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa dla pacjenta"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
          <TextInput
            label="Nazwa  w optimed"
            source="optimedName"
            variant="outlined"
            alwaysOn
            resettable
          />
          <TextInput
            label="Kod"
            source="code"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="code" label="Kod" />
        <TextField source="optimedName" label="Nazwa w optimed" />
        <TextField source="name" label="Nazwa dla pacjenta" />
        <TextField source="price" label="Cena dla pacjenta" />

        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
