import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  required,
  FileInput,
  FileField,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { useDispatch } from "react-redux";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

const CustomTitle = (props) => `Plik ${props.record.id.split("/")[3]}`;

export default (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm
        toolbar={<PostBottomToolbarEdit disabled={loading} />}
        save={(data) => {
          const file = new FormData();
          data?.file?.rawFile &&
            file.append("file", data.file.rawFile, data.file.rawFile.name);
          file.append("name", data.name);
          file.append("short", data.short);
          file.append("type", data.type);
          file.append("sequence", data.sequence);

          dispatch(fetchStart());
          setLoading(true);

          fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}/files/${
              data.id.split("/")[3]
            }`,
            {
              method: "POST",
              body: file,
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
            .then((resp) => {
              if (resp.status != 201) throw resp;
              resp.json().then((respData) => {
                dispatch(fetchEnd());
                setLoading(false);
                redirect("/files");
                notify("Plik został zmieniony");
              });
            })
            .catch((error) => {
              notify(`Wystąpił błąd: ${error.status}`, "warning");
                dispatch(fetchEnd());
                setLoading(false);
            });
        }}
      >
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="filename"
                      label="Nazwa pliku"
                      fullWidth
                      variant="outlined"
                      disabled
                    />
                    <FileInput
                      source="file"
                      label="Plik"
                      accept={["application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","image/*"]}
                      //validate={requiredField}
                    >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      fullWidth
                      variant="outlined"
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="short"
                      label="Opis"
                      fullWidth
                      variant="outlined"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceInput
                      reference="file_types"
                      source="type"
                      label="Kategoria pliku"
                      fullWidth
                      variant="outlined"
                    >
                      <SelectInput variant="outlined" fullWidth />
                    </ReferenceInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberInput
                      source="sequence"
                      label="Kolejność"
                      fullWidth
                      variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
