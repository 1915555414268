import React from "react";
import {BooleanInput, Create, required, SimpleForm, TextInput,} from "react-admin";
import {Field} from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";
import AddFilePopup from "../CustomElements/AddFilePopup";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj dokument`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <SelectInput*/}
                {/*      source="type"*/}
                {/*      variant="outlined"*/}
                {/*      label="Typ"*/}
                {/*      choices={[*/}
                {/*        { id: 0, name: "Test1" },*/}
                {/*        { id: 1, name: "Test2" },*/}
                {/*      ]}*/}
                {/*      fullWidth*/}
                {/*      validate={requiredField}*/}
                {/*    />*/}
                {/*  </td>*/}
                {/*</tr>*/}
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="text">
                      {(props) => <TinyMCEInput source="text" />}
                    </Field>
                  </td>
                </tr>
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <ReferenceArrayInput*/}
                {/*      reference="categories"*/}
                {/*      source="category"*/}
                {/*      label="Kategoria"*/}
                {/*      perPage={1000}*/}
                {/*      fullWidth*/}
                {/*      validate={requiredField}*/}
                {/*    >*/}
                {/*      <AutocompleteArrayInput*/}
                {/*        optionText={*/}
                {/*          (record) =>*/}
                {/*            record &&*/}
                {/*            (record.parent*/}
                {/*              ? `${record.name} (${record.parentName})`*/}
                {/*              : record.name)*/}
                {/*        }*/}
                {/*        variant="outlined"*/}
                {/*        fullWidth*/}
                {/*      />*/}
                {/*    </ReferenceArrayInput>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                <tr>
                  <td>
                    <AddImagePopup
                      source="image"
                      reference="media_objects"
                      label="Zdjęcie główne"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                      source="public"
                      label="Publiczne"
                      variant="outlined"
                    />
                  </td>
                </tr>
                <AddFilePopup source="files" reference="files" />
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
