import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  FormDataConsumer,
  required,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const requiredField = required();

export default (props) => {
  const [position, setPosition] = useState();

  return (
    <Create {...props} title={`Dodaj baner`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setPosition(formData.position);
          }}
        </FormDataConsumer>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nagłówek"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="url"
                      label="URL"
                      variant="outlined"
                      fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup
                        source="image"
                        reference="media_objects"
                        label="Grafika"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                      source="public"
                      label="Publiczne"
                      variant="outlined"
                      initalValue={false}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
