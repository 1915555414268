import React from "react";
import {
  AutocompleteArrayInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput
} from "react-admin";
import {Field} from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

import AddImagePopup from "../CustomElements/AddImagePopup.js";

const CustomTitle = (props) => `Lekarz ${props.record.name}`;

const requiredField = required();

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Imię i nazwisko"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="academicDegree"
                      label="Stopień naukowy"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <SelectInput*/}
                {/*      source="type"*/}
                {/*      label="Typ"*/}
                {/*      variant="outlined"*/}
                {/*      choices={[*/}
                {/*        { id: 0, name: "Typ 1" },*/}
                {/*        { id: 1, name: "Typ 2" },*/}
                {/*        { id: 2, name: "Typ 3" },*/}
                {/*      ]}*/}
                {/*      fullWidth*/}
                {/*      validate={requiredField}*/}
                {/*    />*/}
                {/*  </td>*/}
                {/*</tr>*/}
                <tr>
                  <td>
                    <TextInput
                      source="specialization"
                      label="Specjalizacja"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup source="photo" reference="media_objects" />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="description">
                      {(props) => (
                        <TinyMCEInput
                          source="description"
                          height={200}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberInput
                      source="portalId"
                      label="ID w portalu pacjenta"
                      variant="outlined"
                      fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                        source="formUrl"
                        label="Link do formularza"
                        variant="outlined"
                        fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="clinic"
                      reference="clinics"
                      label="Poradnie"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="hospitalSection"
                        reference="hospital_sections"
                        label="Oddziały szpitala"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="diagnosis"
                        reference="diagnoses"
                        label="Badania diagnostyczne"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="staff"
                        reference="staff"
                        label="Lekarze"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
