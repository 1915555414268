import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj diagnostykę`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="diagnoses"
                      reference="diagnoses"
                      label="Badania diagnostyczne"
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
