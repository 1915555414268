import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  ReferenceInput
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import {Field} from "react-final-form";
import TinyMCEInput from "../TinyMCEInput";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={"Dodaj typ plika"}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-10">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="question"
                      label="Pytanie"
                      fullWidth
                      variant="outlined"
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                    >Odpowiedź</label>
                    <Field name="answer">
                      {(props) => (
                          <TinyMCEInput
                              source="answer"
                              height={200}
                          />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceInput
                        reference="faq_types"
                        source="type"
                        label="Kategoria"
                        validate={requiredField}
                        fullWidth
                    >
                      <SelectInput variant="outlined" fullWidth />
                    </ReferenceInput>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
