import React, { useState } from "react";
import { useForm } from "react-final-form";
import { Editor } from "@tinymce/tinymce-react";
import * as _ from "lodash";

//import "./CustomImagePlugin";

const TinyMCEInput = ({ source, ...props }) => {
  const form = useForm();
  const firstInit = _.get(form.getState().initialValues, source, "");
  const [init] = useState(firstInit);

  const debouncedOnChange = _.debounce((text) => {
    form.change(source, text);
  }, 500);

  return (
    <>
      <div>
        <Editor
            apiKey='6opw8w5zbugd1523uz5wbgf0mmletiawv5b2i6p5n4tw6szf'
          init={{
            id: props.id,
            height: props.height ? props.height : 500,
            menubar: props.menubar != undefined ? props.menubar : true,
            external_plugins: {
              "image-plugin": "/image-plugin/plugin.js",
              "gallery-plugin": "/gallery-plugin/plugin.js",
            },
            relative_urls: false,
            content_css: [
              "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css",
              "https://szpital.a100.com.pl/css/styles.css",
            ],
            remove_script_host: true,
            plugins: props.plugins
              ? props.plugins
              : [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount template",
                ],
            toolbar: props.toolbar
              ? props.toolbar
              : "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | template image-plugin | gallery-plugin | help ",
            templates: [
              {
                title: "Box produktowy",
                description: "Dodanie boksu z produktem",
                content:
                  ' <div class="d-inline-flex flex-column radius-3 shadow-blue bg-white p-4 col-12 col-md-3 tiny-prod-box">\n' +
                  "\n" +
                  '                    <div class="position-relative text-center pb-3">\n' +
                  '                        <a href="#" title=""><img src="https://szpital.a100.com.pl/images/doc-placeholder.jpg" alt="" class="img-fluid shadow-blue radius-3 doc-list-photo "/></a>\n' +
                  "                    </div>\n" +
                  "\n" +
                  '                    <h5 class="mb-3 text-dark doc-cat-heading fw-normal">\n' +
                  '                        <span class="bg-white pe-2 d-inline-block position-relative">Nazwa produktu</span>\n' +
                  "                    </h5>\n" +
                  '                    <div class="fs-7 mb-4">Interdum et malesuada fames ac ante ipsum primis in faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque commodo sagittis auctor.</div>\n' +
                  '                    <a href="#" title="więcej" class="py-3 px-4 text-light d-inline-block rounded-pill bg-green text-center btn-small mt-auto w-100 text-decoration-none">zobacz produkt</a>\n' +
                  "                </div>",
              },
            ],
          }}
          initialValue={init}
          onEditorChange={(content) => {
            debouncedOnChange(content);
          }}
        />
      </div>
    </>
  );
};

export default TinyMCEInput;
