import * as React from "react";
import {
  ChipField,
  Filter,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Lista typów diagnostyk`}
      perPage={25}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      pagination={<CustomPagination />}
      empty={<EmptyList title="typów diagnostyk" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />

        <ReferenceArrayField
          source="diagnoses"
          reference="diagnoses"
          label="Badania diagnostyczne"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
