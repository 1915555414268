import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  BooleanInput
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";
import AddFilePopup from "../CustomElements/AddFilePopup";

const requiredField = required();

const CustomTitle = (props) => `Poradnia ${props.record.name}`;

export default (props) => {
  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup source="icon" reference="media_objects" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="description">
                      {(props) => <TinyMCEInput source="description" />}
                    </Field>
                  </td>
                </tr>
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <label*/}
                {/*      style={{*/}
                {/*        fontFamily: "arial",*/}
                {/*        color: "rgba(0, 0, 0, 0.54)",*/}
                {/*        display: "block",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      Informacja dodatkowa*/}
                {/*    </label>*/}
                {/*    <Field name="additionalInformation">*/}
                {/*      {(props) => (*/}
                {/*        <TinyMCEInput*/}
                {/*          source="additionalInformation"*/}
                {/*          height={200}*/}
                {/*          menubar={false}*/}
                {/*        />*/}
                {/*      )}*/}
                {/*    </Field>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <AddImagePopup*/}
                {/*      source="banner"*/}
                {/*      reference="media_objects"*/}
                {/*      label="Baner"*/}
                {/*    />*/}
                {/*  </td>*/}
                {/*</tr>*/}
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="clinics"
                      reference="clinics"
                      label="Poradni"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="diagnosis"
                        reference="diagnoses"
                        label="Diagnostyki"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddFilePopup
                      source="files"
                      reference="files"
                      label="Do pobrania"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="staff"
                      reference="staff"
                      label="Lekarze"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="prices"
                        reference="prices"
                        label="Cennik"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="blogs"
                        reference="blogs"
                        label="Artykuły blogowe"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
