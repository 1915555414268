import * as React from "react";
import {
  DateField,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  Pagination,
} from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";
import { GetPermissionNamesFromArray } from "../../config/PermissionHandler";

export default (props) => {
  return (
    <List
      {...props}
      title={`Użytkownicy`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="użytkowników" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="E-mail"
            source="email"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="username" label="Nazwa użytkownika" />
        <FunctionField
          render={(record) => GetPermissionNamesFromArray(record.roles)}
          label="Uprawienia"
        />
        <DateField source="createdAt" label="Utworzono" />
        <DateField source="updatedAt" label="Modyfikowano" />
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
