import * as React from "react";

import {CreateButton, DeleteButton, EditButton, ExportButton} from "react-admin";


export const CustomCreateButton = (props) => {
    return (
        <CreateButton class={"btn btn-outline-success"} {...props} />
    );
};

export const CustomExportButton = (props) => {
    return (
        <ExportButton class={"btn btn-outline-info"} {...props} />
    );
};

export const CustomEditButton = (props) => {
    return (
        <EditButton
            class={"btn btn-outline-secondary btn-sm"}
            {...props}
            label={props.editLabel ? props.editLabel : "Edytuj"}
        />
    );
};

export const CustomDeleteButton = (props) => {
    //const refresher = useRefresh(true);
    return (
        <DeleteButton
            class={"btn btn-outline-danger btn-sm"}
            {...props}
            label={props.deleteLabel ? props.deleteLabel : "Usuń"}
            onSuccess={props.onSuccess && props.onSuccess}
            mutationMode={props.notUndoable ? "pessimistic" : "undoable"}
        />
    );
};