import React, {useState} from "react";
import {ArrayInput, Create, FormDataConsumer, required, SimpleForm, SimpleFormIterator, TextInput,} from "react-admin";


import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";
import AddImagesPopup from "../CustomElements/AddImagesPopup";

const requiredField = required();

export default (props) => {
  const [position, setPosition] = useState();

  return (
    <Create {...props} title={`Dodaj baner`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setPosition(formData.position);
          }}
        </FormDataConsumer>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <ArrayInput source="images" label={"Zdjęcia"}>
                  <SimpleFormIterator>
                    <AddImagesPopup source="" reference="media_objects" />
                  </SimpleFormIterator>
                </ArrayInput>
                {/*https://github.com/coding-blocks/cb-junior-admin/blob/master/src/lib/OrderedArrayInput.jsx#L26*/}
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
