import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  required,
  FileInput,
  FileField,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={"Dodaj plik"}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <FileInput
                      source="file"
                      label="Plik"
                      accept={["application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","image/*"]}
                      validate={requiredField}
                    >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      fullWidth
                      variant="outlined"
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="short"
                      label="Opis"
                      fullWidth
                      variant="outlined"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceInput
                      reference="file_types"
                      source="type"
                      label="Typ pliku"
                      fullWidth
                      variant="outlined"
                    >
                      <SelectInput variant="outlined" fullWidth />
                    </ReferenceInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberInput
                      source="sequence"
                      label="Kolejność"
                      fullWidth
                      variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
