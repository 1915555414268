import React from "react";
import {Redirect, Route} from "react-router-dom";
//import { MenuItemLink, usePermissions } from "react-admin";
import {
    fetchHydra as baseFetchHydra,
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";

import {Resource} from "react-admin";

// config
//const { permissions } = usePermissions();
import authProvider from "./config/authProvider";
import customRoutes from "./config/customRoutes";
import {PermissionHandler} from "./config/PermissionHandler";

// pages
//import Login from "./layout/Login";
import Login from "./layout/Login/Login";
import Menu from "./layout/Menu/Menu";
//import Dashboard from "./layout/Dashboard/Dashboard";
//import NotFound from "./layout/NotFound";
import MainLayout from "./layout/MainLayout";
import CategoriesList from "./layout/Categories/CategoriesList";
import UsersList from "./layout/Users/UsersList";


import MediaObjectsList from "./layout/MediaObjects/MediaObjectsList";
import MediaObjectsCreate from "./layout/MediaObjects/MediaObjectsCreate";
import MediaObjectsEdit from "./layout/MediaObjects/MediaObjectsEdit";

import ContentsList from "./layout/Contents/ContentsList";
import ContentsCreate from "./layout/Contents/ContentsCreate";
import ContentsEdit from "./layout/Contents/ContentsEdit";

import UsersCreate from "./layout/Users/UsersCreate";
import UsersEdit from "./layout/Users/UsersEdit";

import CategoriesEdit from "./layout/Categories/CategoriesEdit";
import CategoriesCreate from "./layout/Categories/CategoriesCreate";

import BannersList from "./layout/Banners/BannersList";
import BannersCreate from "./layout/Banners/BannersCreate";
import BannersEdit from "./layout/Banners/BannersEdit";

import BlogsList from "./layout/Blogs/BlogsList";
import BlogsEdit from "./layout/Blogs/BlogsEdit";
import BlogsCreate from "./layout/Blogs/BlogsCreate";

import BlogTagsList from "./layout/BlogTags/BlogTagsList";
import BlogTagsEdit from "./layout/BlogTags/BlogTagsEdit";
import BlogTagsCreate from "./layout/BlogTags/BlogTagsCreate";

import ClinicsList from "./layout/Clinics/ClinicsList";
import ClinicsEdit from "./layout/Clinics/ClinicsEdit";
import ClinicsCreate from "./layout/Clinics/ClinicsCreate";

import DiagnosesList from "./layout/Diagnoses/DiagnosesList";
import DiagnosesEdit from "./layout/Diagnoses/DiagnosesEdit";
import DiagnosesCreate from "./layout/Diagnoses/DiagnosesCreate";

import TypesOfDiagnosisList from "./layout/TypesOfDiagnosis/TypesOfDiagnosisList";
import TypesOfDiagnosisEdit from "./layout/TypesOfDiagnosis/TypesOfDiagnosisEdit";
import TypesOfDiagnosisCreate from "./layout/TypesOfDiagnosis/TypesOfDiagnosisCreate";

import StaffList from "./layout/Staff/StaffList";
import StaffEdit from "./layout/Staff/StaffEdit";
import StaffCreate from "./layout/Staff/StaffCreate";

import HospitalSectionsList from "./layout/HospitalSections/HospitalSectionsList";
import HospitalSectionsEdit from "./layout/HospitalSections/HospitalSectionsEdit";
import HospitalSectionsCreate from "./layout/HospitalSections/HospitalSectionsCreate";

import NewsList from "./layout/News/NewsList";
import NewsEdit from "./layout/News/NewsEdit";
import NewsCreate from "./layout/News/NewsCreate";

import FilesList from "./layout/Files/FilesList";
import FilesCreate from "./layout/Files/FilesCreate";
import FilesEdit from "./layout/Files/FilesEdit";

import FileTypesList from "./layout/FileTypes/FileTypesList";
import FileTypesCreate from "./layout/FileTypes/FileTypesCreate";
import FileTypesEdit from "./layout/FileTypes/FileTypesEdit";

import FaqList from "./layout/Faq/FaqList";
import FaqCreate from "./layout/Faq/FaqCreate";
import FaqEdit from "./layout/Faq/FaqEdit";

import FaqTypeList from "./layout/FaqType/FaqTypeList";
import FaqTypeCreate from "./layout/FaqType/FaqTypeCreate";
import FaqTypeEdit from "./layout/FaqType/FaqTypeEdit";

import PriceListList from "./layout/PriceList/PriceListList";
import PriceListCreate from "./layout/PriceList/PriceListCreate";
import PriceListEdit from "./layout/PriceList/PriceListEdit";

import GalleryList from "./layout/Gallery/GalleryList";
import GalleryCreate from "./layout/Gallery/GalleryCreate";
import GalleryEdit from "./layout/Gallery/GalleryEdit";

import InformationListList from "./layout/InformationList/InformationListList";
import InformationListCreate from "./layout/InformationList/InformationListCreate";
import InformationListEdit from "./layout/InformationList/InformationListEdit";




//import Dashboard from "./layout/Dashboard";
// pages - end
//stylesheet
import "./css/style.css";
//
// i18n
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import polishMessages from "ra-language-polish";

const messages = {
    pl: polishMessages,
    en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pl");
// i18n - end

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("token")}`,
});
const fetchHydra = (url, options = {}) =>
    localStorage.getItem("token")
        ? baseFetchHydra(url, {
            ...options,
            headers: new Headers(fetchHeaders()),
        })
        : baseFetchHydra(url, options);
const apiDocumentationParser = (entrypoint) =>
    parseHydraDocumentation(
        entrypoint,
        localStorage.getItem("token")
            ? {
                headers: new Headers(fetchHeaders()),
            }
            : {}
    ).then(
        ({api}) => ({api}),
        (result) => {
            // Only useful when the API endpoint is secured
            if (result.status === 401) {
                // Prevent infinite loop if the token is expired
                localStorage.removeItem("token");
                return Promise.resolve({
                    api: result.api,
                    customRoutes: [
                        <Route
                            path="/"
                            render={() =>
                                localStorage.getItem("token") ? (
                                    window.location.reload()
                                ) : (
                                    <Redirect to="/login"/>
                                )
                            }
                        />,
                    ],
                });
            }
            return Promise.reject(result);
        }
    );
const dataProvider = baseHydraDataProvider(
    entrypoint,
    fetchHydra,
    apiDocumentationParser
);

export default () => (
    <>
        <HydraAdmin
            title="Panel CMS A100"
            locale="pl"
            entrypoint={entrypoint}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            customRoutes={customRoutes}
            // pages
            menu={Menu}
            loginPage={Login}
            //dashboard={Dashboard}
            layout={MainLayout}
            //catchAll={NotFound}
        >
            {/* PermissionHandler(permissions, "PkUsers", "Read") && (
        <Resource
          name="pk_users"
          list={PkUserList}
          create={PermissionHandler(permissions, "PkUsers", "Create") &&PkUserCreate}
          edit={PermissionHandler(permissions, "PkUsers", "Update") &&PkUserEdit}
        />
      ), */}
            {(permissions) => [
                PermissionHandler(permissions, "Blogs", "Read") && (
                    <Resource
                        name="blogs"
                        list={BlogsList}
                        edit={
                            PermissionHandler(permissions, "Blogs", "Update") && BlogsEdit
                        }
                        create={
                            PermissionHandler(permissions, "Blogs", "Create") && BlogsCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "Clinics", "Read") && (
                    <Resource
                        name="clinics"
                        list={ClinicsList}
                        edit={
                            PermissionHandler(permissions, "Clinics", "Update") && ClinicsEdit
                        }
                        create={
                            PermissionHandler(permissions, "Clinics", "Create") &&
                            ClinicsCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "Diagnoses", "Read") && (
                    <Resource
                        name="diagnoses"
                        list={DiagnosesList}
                        edit={
                            PermissionHandler(permissions, "Diagnoses", "Update") &&
                            DiagnosesEdit
                        }
                        create={
                            PermissionHandler(permissions, "Diagnoses", "Create") &&
                            DiagnosesCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "TypeOfDiagnoses", "Read") && (
                    <Resource
                        name="type_of_diagnoses"
                        list={TypesOfDiagnosisList}
                        edit={
                            PermissionHandler(permissions, "TypeOfDiagnoses", "Update") &&
                            TypesOfDiagnosisEdit
                        }
                        create={
                            PermissionHandler(permissions, "TypeOfDiagnoses", "Create") &&
                            TypesOfDiagnosisCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "Staff", "Read") && (
                    <Resource
                        name="staff"
                        list={StaffList}
                        edit={
                            PermissionHandler(permissions, "Staff", "Update") && StaffEdit
                        }
                        create={
                            PermissionHandler(permissions, "Staff", "Create") && StaffCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "HospitalSections", "Read") && (
                    <Resource
                        name="hospital_sections"
                        list={HospitalSectionsList}
                        edit={
                            PermissionHandler(permissions, "HospitalSections", "Update") &&
                            HospitalSectionsEdit
                        }
                        create={
                            PermissionHandler(permissions, "HospitalSections", "Create") &&
                            HospitalSectionsCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "News", "Read") && (
                    <Resource
                        name="news"
                        list={NewsList}
                        edit={PermissionHandler(permissions, "News", "Update") && NewsEdit}
                        create={
                            PermissionHandler(permissions, "News", "Create") && NewsCreate
                        }
                    />
                ),

                PermissionHandler(permissions, "Files", "Read") && (
                    <Resource
                        name="files"
                        list={FilesList}
                        create={
                            PermissionHandler(permissions, "Files", "Create") && FilesCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Files", "Update") && FilesEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "FileTypes", "Read") && (
                    <Resource
                        name="file_types"
                        list={FileTypesList}
                        create={
                            PermissionHandler(permissions, "FileTypes", "Create") &&
                            FileTypesCreate
                        }
                        edit={
                            PermissionHandler(permissions, "FileTypes", "Update") &&
                            FileTypesEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "FaqType", "Read") && (
                    <Resource
                        name="faq_types"
                        list={FaqTypeList}
                        create={
                            PermissionHandler(permissions, "FaqType", "Create") &&
                            FaqTypeCreate
                        }
                        edit={
                            PermissionHandler(permissions, "FaqType", "Update") &&
                            FaqTypeEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "Faq", "Read") && (
                    <Resource
                        name="faqs"
                        list={FaqList}
                        create={
                            PermissionHandler(permissions, "Faq", "Create") &&
                            FaqCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Faq", "Update") &&
                            FaqEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "Gallery", "Read") && (
                    <Resource
                        name="galleries"
                        list={GalleryList}
                        create={
                            PermissionHandler(permissions, "Gallery", "Create") &&
                            GalleryCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Gallery", "Update") &&
                            GalleryEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "InformationList", "Read") && (
                    <Resource
                        name="information_lists"
                        list={InformationListList}
                        create={
                            PermissionHandler(permissions, "InformationList", "Create") &&
                            InformationListCreate
                        }
                        edit={
                            PermissionHandler(permissions, "InformationList", "Update") &&
                            InformationListEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "BlogTags", "Read") && (
                    <Resource
                        name="blog_tags"
                        list={BlogTagsList}
                        edit={
                            PermissionHandler(permissions, "BlogTags", "Update") &&
                            BlogTagsEdit
                        }
                        create={
                            PermissionHandler(permissions, "BlogTags", "Create") &&
                            BlogTagsCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "Categories", "Read") && (
                    <Resource
                        name="categories"
                        list={CategoriesList}
                        edit={
                            PermissionHandler(permissions, "Categories", "Update") &&
                            CategoriesEdit
                        }
                        create={
                            PermissionHandler(permissions, "Categories", "Create") &&
                            CategoriesCreate
                        }
                    />
                ),
                PermissionHandler(permissions, "Banners", "Read") && (
                    <Resource
                        name="banners"
                        list={BannersList}
                        create={
                            PermissionHandler(permissions, "Banners", "Create") &&
                            BannersCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Banners", "Update") && BannersEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "Contents", "Read") && (
                    <Resource
                        name="contents"
                        list={ContentsList}
                        create={
                            PermissionHandler(permissions, "Contents", "Create") &&
                            ContentsCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Contents", "Update") &&
                            ContentsEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "MediaObjects", "Read") && (
                    <Resource
                        name="media_objects"
                        list={MediaObjectsList}
                        create={
                            PermissionHandler(permissions, "MediaObjects", "Create") &&
                            MediaObjectsCreate
                        }
                        edit={
                            PermissionHandler(permissions, "MediaObjects", "Update") &&
                            MediaObjectsEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "PriceList", "Read") && (
                    <Resource
                        name="prices"
                        list={PriceListList}
                        create={
                            PermissionHandler(permissions, "PriceList", "Create") &&
                            PriceListCreate
                        }
                        edit={
                            PermissionHandler(permissions, "PriceList", "Update") &&
                            PriceListEdit
                        }
                    />
                ),
                PermissionHandler(permissions, "Files", "Read") && (
                    <Resource name="files" list={FilesList}/>
                ),
                PermissionHandler(permissions, "FileTypes", "Read") && (
                    <Resource name="file_types"/>
                ),
                PermissionHandler(permissions, "Users", "Read") && (
                    <Resource
                        name="users"
                        list={UsersList}
                        create={
                            PermissionHandler(permissions, "Users", "Create") && UsersCreate
                        }
                        edit={
                            PermissionHandler(permissions, "Users", "Update") && UsersEdit
                        }
                    />
                ),
            ]}
        </HydraAdmin>
        <div class="footer" id="footerA100">
      <span>
        made by{" "}
          <a href="https://a100.com.pl/" target="_blank">
          A100
        </a>
      </span>
        </div>
    </>
);
