import * as React from "react";
import {
  Filter,
  List,
  TextField,
  TextInput,
  BooleanField,
  Pagination,
} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

const GetType = (position) => {
  switch (position) {
    case 0:
      return "Type 1";
    case 1:
      return "Type 2";
    case 2:
      return "Type 3";
    default:
      return "Błąd typu";
  }
};

export default (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title={`Lista lekarzy`}
      perPage={100}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="lekarzów" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Imię i nazwisko"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="academicDegree" label="Stopień naukowy" />
        <TextField source="name" label="Imię i nazwisko" />

        <TextField source="specialization" label="Specjalizacja" />
        <BooleanField source="public" label="Opublikowany" />

        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
