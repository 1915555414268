import React from "react";
import {Edit, required, SimpleForm, TextInput,} from "react-admin";

import {PostBottomToolbarEdit} from "../CustomElements/PostBottomToolbars";

const requiredField = required();

const CustomTitle = (props) => `Użytkownik ${props.record.usernama}`;

export default (props) => {
  return (
    <Edit {...props} title={`Dodaj kontent`}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-md-4">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
