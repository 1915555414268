import * as React from "react";
import {Filter, List, TextField, TextInput,} from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {CustomActionsButtons} from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import {CustomPagination} from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Boxy tekstowe`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="Boxy tekstowe" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Informacja"
            source="identifier"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="identifier" label="Informacja" />
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
