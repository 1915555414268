import * as React from "react";
import { Route } from 'react-router-dom';
import ResetPassword from '../layout/Login/ResetPassword';
import GalleriesPluginGallery from "../layout/TinyMCE-PluginsViews/GalleriesPluginGallery";
import ImagePluginGallery from "../layout/TinyMCE-PluginsViews/ImagePluginGallery";


export default [
  <Route exact path="/reset_password" component={ResetPassword} noLayout />,
  <Route exact path="/tinymce_image_gallery" component={ImagePluginGallery} noLayout />,
  <Route exact path="/tinymce_galleries" component={GalleriesPluginGallery} noLayout />,
];
