import * as React from "react";
import {cloneElement} from "react";
import {makeStyles} from "@material-ui/core/styles";

import {CustomCreateButton, CustomExportButton} from './CustomButtons'

import {sanitizeListRestProps, TopToolbar, useListContext,} from "react-admin";

const toolbarStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const CustomActionBar = (props) => {
  const {
    resetFilters = false,
    filtersDataFromRecords = null,
    filtersDataChoices = null,
    isAddVisible = true,
    isFiltersVisible = true,
        isExported = false,
    className,
    exporter,
    filters,
    maxResults,
    tabs,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    setFilters,
    total,
  } = useListContext();

  React.useEffect(() => {
    resetFilters && setFilters({});
  }, [resetFilters]);

  return (
    <TopToolbar
      //className={[className, toolbarStyles().root]}
            class={"navbar "}
      {...sanitizeListRestProps(rest)}
    >
      {tabs && tabs}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <div className="btn-group" role="group">
        {isExported && <CustomExportButton/>}
      {isAddVisible && <CustomCreateButton basePath={basePath} />}
            </div>
    </TopToolbar>
  );
};

export default CustomActionBar;
