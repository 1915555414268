import React, { ReactDOM } from "react";
import { Layout, AppBar, ImageField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import "../App.css"; //plik css
import logo from "../logo.png";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: -10,
  },

  logo: {
    height: 46,
    marginLeft: "10px",
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      className={"top-admin-bar"}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <Typography variant="h6" style={{ color: "#3C424A" }}>
        Witaj w panelu admina
      </Typography>

      <img src={logo} alt="logo" className={classes.logo} />
      <div className={"spacer"}></div>
      <Typography
        variant="h6"
        style={{ color: "#3C424A" }}
        id="react-admin-title"
      />
    </AppBar>
  );
};

const MainLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

export default MainLayout;
