import React, {useState} from "react";
import {ArrayInput, Edit, FormDataConsumer, required, SimpleForm, SimpleFormIterator, TextInput,} from "react-admin";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";
import AddImagesPopup from "../CustomElements/AddImagesPopup";

const CustomTitle = (props) => `Baner ${props.record.name}`;

const requiredField = required();

export default (props) => {
  const [position, setPosition] = useState();

  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setPosition(formData.position);
          }}
        </FormDataConsumer>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                        source="name"
                        label="Nazwa"
                        variant="outlined"
                        fullWidth
                        validate={requiredField}
                    />
                  </td>
                </tr>
                <ArrayInput source="images">
                  <SimpleFormIterator>
                    <AddImagesPopup source="" reference="media_objects" />
                  </SimpleFormIterator>
                </ArrayInput>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
