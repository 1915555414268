import * as React from "react";
import { BooleanField, Filter, List, TextField, TextInput } from "react-admin";
import CustomDataGrid from "../CustomElements/CustomDataGrid";
import { CustomActionsButtons } from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "block",
    },
  })
);

export default (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title={`Aktualności`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="aktualności" add={true} />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        <BooleanField
          source="showOnMainSite"
          label="Wyświetlaj na stronie głównej"
          classes={classes}
        />
        <BooleanField source="public" label="Opublikowany" classes={classes} />
        <CustomActionsButtons label="Akcje" notUndoable={true} />
      </CustomDataGrid>
    </List>
  );
};
