import React, { useState, useEffect } from "react";

import {
  SimpleForm,
  ImageField,
  TextField,
  CardActions,
  useQuery,
  useCreateController,
  CreateContextProvider,
  ImageInput,
  Toolbar,
  SaveButton,
} from "react-admin";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  createStyles,
  makeStyles,
  withStyles,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import keyBy from "lodash/keyBy";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, useForm } from "react-final-form";
import { useDataProvider } from "react-admin";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

const CreateGalleryPopup = (props) => {
  const useStylesCards = makeStyles(() =>
    createStyles({
      root: {
        overflow: "visible",
      },
    })
  );
  const cardClasses = useStylesCards();

  const [name, setName] = useState(null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [sort, setSort] = useState({ field: "id", order: "ASC" });
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: props.reference,
    payload: {
      pagination: { page, perPage },
      sort,
      filter: name ? { name: name } : {},
    },
  });

  const GalleryPagination = (props) => {
    const nbPages = Math.ceil(total / perPage) || 1;
    return (
      nbPages > 1 && (
        <Toolbar style={{ justifyContent: "space-between" }}>
          {page > 1 && (
            <Button
              color="primary"
              key="prev"
              onClick={() => setPage(page - 1)}
            >
              <ChevronLeft />
              Wstecz
            </Button>
          )}
          {page !== nbPages && (
            <Button
              color="primary"
              key="next"
              onClick={() => setPage(page + 1)}
            >
              Dalej
              <ChevronRight />
            </Button>
          )}
          <div>
            <span>Elementów na stronę:</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => {
                setPerPage(e.target.value);
              }}
              value={perPage}
              style={{ margin: "0 10px 0 10px" }}
            >
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={16}>16</MenuItem>
            </Select>
          </div>
        </Toolbar>
      )
    );
  };

  return (
    <>
      <Card classes={cardClasses}>
        <CardContent>
          <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Wybierz z repozytorium
          </Typography>

          <div style={{ flex: 1 }}>
            <label className="popup-label">Nazwa galerii</label>
            <input
              label="Nazwa pliku"
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
                setPage(1);
              }}
              value={name}
              className="form-control"
              style={{ width: "50%" }}
            />
            {loading && (
              <CircularProgress
                size={75}
                style={{ margin: "115px 0 115px 75px" }}
              />
            )}
            {error && <p>Błąd: {error}</p>}
            {!loading && !error && (
              <GalleriesGrid
                data={keyBy(data, "id")}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
                setGallerySlug={props.setGallerySlug}
                handleVisability={props.handleVisability}
              />
            )}
            {!error && <GalleryPagination />}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const GalleriesGrid = (props) => {
  const { ids, data, basePath } = props;

  const cardStyle = {
    width: 150,
    margin: "0.5em",
    display: "inline-block",
    verticalAlign: "top",
    wordWrap: "break-word",
    cursor: "pointer",
  };

  const useStyles = makeStyles({
    root: {
      "&:hover": { backgroundColor: "#c3e6cb" },
    },
  });
  const classesCard = useStyles();
  return (
    <div className="popup-cards-wrapper">
      {ids.map((id) => (
        <Card
          key={id}
          style={cardStyle}
          onClick={() => {
            props.setGallerySlug(data[id]["@id"]);
            let value = {
              gallerySlug: data[id].slug,
            };
            window.parent.tinymce.activeEditor.execCommand(
              "iframeCommandGalleries",
              0,
              value
            );
            setTimeout(() => {
              window.parent.tinymce.activeEditor.windowManager.close();
            }, 500);
          }}
          classes={classesCard}
        >
          <CardContent>
            <TextField record={data[id]} source="name" />
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}></CardActions>
        </Card>
      ))}
    </div>
  );
};

export default (props) => {
  const [gallerySlug, setGallerySlug] = useState(null);

  return (
    <div>
      <CreateGalleryPopup
        setGallerySlug={setGallerySlug}
        reference={"galleries"}
      />
    </div>
  );
};
