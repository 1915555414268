import * as React from "react";
import {CustomDeleteButton, CustomEditButton} from './CustomButtons'

export const CustomActionsButtons = (props) => {
  const {
    isEditAvailable = true,
    isDeleteAvailable = true,
  } = props;
  return (
      <div class="btn-group" role="group">
        {isEditAvailable && <CustomEditButton {...props} />}
        {isDeleteAvailable && <CustomDeleteButton {...props} />}
      </div>
  );
};

export default CustomActionsButtons;
